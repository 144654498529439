import { TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import apis from "../../../api"
import CommonDialog from "../../../Component/CommonDialog"
import { MemberLevelOption, SingleOptionList } from "../../../Component/MuiEx"
import { apiResult, formatDate, getUserSession } from "../../../Utils/Common"

const MemberLevelDialog = (props) =>{
    const {member,onClose,onSubmit} = props
    const [sdate,setDate] = useState(formatDate(new Date()))
    const [edate,setEDate] = useState()
    const [level,setLevel] = useState()    
    const [error,setError] = useState()
    getUserSession(apis)
    useEffect(()=>{
        setLevel(member.level)
    },[member])
    const handleSubmit = ()=>{
        apis.changeMemberLevel(member.id,level,sdate,edate).then(ret=>{
            setError()
            apiResult(ret,data=>{
                onSubmit&&onSubmit(level,sdate)
            },setError)
        })
    }
    return <CommonDialog errorHint={error} dialogTitle={"Change member level"} onClose={onClose} onSubmit={handleSubmit}>
    <Typography gutterBottom>
        Change member level to:
    </Typography>
    <SingleOptionList defaultValue={level} items={MemberLevelOption().levels} values={MemberLevelOption().values} name="level" onChange={(n, v) => {
        setLevel(v)
    }}></SingleOptionList>
    <TextField margin="normal" type='date' fullWidth label="Begin Date" defaultValue={sdate} onChange={(e)=>{setDate(e.target.value)}} InputLabelProps={{shrink:true}} />    
    <TextField margin="normal" type='date' fullWidth label="Expired Date" defaultValue={member.level_expired} onChange={(e)=>{setEDate(e.target.value)}} InputLabelProps={{shrink:true}} />    
</CommonDialog>
}

export default MemberLevelDialog